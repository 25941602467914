<template>
  <section class="hero-image">
    <div class="hero-txt">
      <h1>{{ $t("home.saveMoney") }}</h1>
      <p>{{ $t("home.bookSmart") }}</p>
      <div class="great-offers">
        <b-form inline @submit.prevent="findPlaces">
          <b-input-group>
            <div class="relative">
              <b-form-input
                id="inline-form-input-name"
                class="banner-input-rule"
                autocomplete="off"
                :placeholder="$t('home.goingWhere')"
                :value="location.destination"
                readonly
                @click="$refs.typesenseSearch.show()"
              ></b-form-input>
              <TypesenseSearch ref="typesenseSearch" @select="setLocation" />
            </div>
            <b-button type="submit" variant="primary" class="find">{{
              $t("home.findOffers")
            }}</b-button>
          </b-input-group>
        </b-form>
      </div>
    </div>
  </section>
</template>

<script>
import TypesenseSearch from "@/components/Widgets/TypesenseSearch";

export default {
  name: "HeroSection",
  data() {
    return {
      location: {
        destination: "",
        latitude: 0,
        longitude: 0,
      },
    };
  },
  components: {
    TypesenseSearch,
  },
  methods: {
    findPlaces() {
      let { latitude, longitude, destination } = this.location;
      if (latitude && longitude) {
        if (!destination) {
          destination = this.$t("home.nearYou");
        }
        this.$router.push({
          path: "listing",
          query: { latitude, longitude, destination },
        });
      }
    },
    setLocation(data) {
      this.location = data;
      setTimeout(() => this.findPlaces(), 500);
    },
  },
};
</script>

<style>
.disabled {
  color: #ddd !important;
  cursor: default;
}
.pac-container:after {
  background-image: none !important;
  height: 0;
}
</style>

<style scoped>
@media screen and (max-width: 767px) {
  .typesense-search-widget {
    top: 56px !important;
    left: 0 !important;
  }
}
</style>
